.App {
    text-align: center;
}

.App-header {
    color: white;
    background-color: rgba(48, 39, 34, 0.95);
}

.App-header hr {
    background-color: black;
}

.App-StairsType-Container {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
}