body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-steps-title {
    font-size: 10px;
}

/*p-step*/
body .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: rgb(227, 192, 111);
}

body .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2em #e3c06f;
}
/*END p-steps*/

/*p-selectbutton*/
body .p-selectbutton .p-button.p-highlight,
body .p-selectbutton .p-button.p-highlight:hover,
body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
    box-shadow: 0 0 0 0.2em #e3c06f;
}

body .p-selectbutton .p-button.p-focus,
body .p-selectbutton .p-button:focus {
    box-shadow: 0 0 0 0.2em #e3c06f;
}

body .p-selectbutton .p-button.p-highlight,
body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
    color: inherit;
}

body .p-selectbutton .p-button {
    color: white;
    background-color: rgba(48, 39, 34, 0.95);
    border-color: rgba(48, 39, 34, 0.95);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: rgb(43, 41, 41);
    border-color: rgb(43, 41, 41);
    color: white;
}

body .p-selectbutton .p-button {
    font-size: 1rem;
}

/*END p-selectbutton*/

body .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2em #e3c06f;
    border-color: rgb(227, 192, 111);
}

body .p-fileupload .p-fileupload-buttonbar {
    background-color: rgba(48, 39, 34, 0.95);
    border-color: rgba(48, 39, 34, 0.95);
}

/*btn-primary*/
.btn-primary {
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
    color: black;
}

.btn-primary:hover {
    color: inherit;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: inherit;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
}

.btn-primary:focus, .btn-primary.focus {
    color: inherit;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
    box-shadow: 0 0 0 0.2em #e3c06f;
}

/*END btn-primary*/

/*btn-secondary*/
.btn-secondary {
    background-color: rgba(48, 39, 34, 0.95);
    border-color: rgba(48, 39, 34, 0.95);
    color: white;
}

.btn-secondary:hover {
    color: white;
    background-color: rgb(43, 41, 41);
    border-color: rgb(43, 41, 41);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: white;
    background-color: rgb(43, 41, 41);
    border-color: rgb(43, 41, 41);
}

.btn-secondary:focus, .btn-secondary.focus {
    color: white;
    background-color: rgb(43, 41, 41);
    border-color: rgb(43, 41, 41);
    box-shadow: 0 0 0 0.2em rgb(43, 41, 41);
}

/*END btn-secondary*/

/*p-fileupload*/
.p-fileupload .p-fileupload-buttonbar button {
    display: none;
}

.p-fileupload-choose.p-button,
.p-fileupload .p-button {
    color: black;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
}


body .p-fileupload-choose:not(.p-disabled):hover,
body .p-button:enabled:hover {
    color: black;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);
}

body .p-fileupload-choose:not(.p-disabled):active,
body .p-button:enabled:active {
    color: black;
    background-color: rgb(227, 192, 111);
    border-color: rgb(227, 192, 111);

}

body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus,
body .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2em #e3c06f;
}

/*END p-fileupload*/