#surroundingWallsRectange {
    margin: auto;
    width: 200px;
    height: 200px;
    background: white;
    border: 16px solid lightgrey;
}

#surroundingWallsRectange.top-border {
    border-top: 16px solid grey;
}


#surroundingWallsRectange.bottom-border {
    border-bottom: 16px solid grey;
}

#surroundingWallsRectange.left-border {
    border-left: 16px solid grey;
}


#surroundingWallsRectange.right-border {
    border-right: 16px solid grey;
}